/* BlogPage.css */
/* Style for the tab content */
.tab-content {
  padding: 20px;
}


.banner {
  height: 350px;
  width: auto;
  margin: auto;
  border-radius: 1rem;
}

.content {
  padding: 0 80px;
}


@media screen and (min-width:320px) and (max-width:480px) {
  .content {
    padding: 0 5px;
  }

  .banner {
    height: 200px;

  }
}

@media screen and (min-width:480px) and (max-width:768px) {
  .content {
    padding: 0 30px;
  }

  .banner {
    height: 250px;

  }
}

/* Style for the tab headers */
/* .nav-tabs .nav-item {
    // Your custom styles for tab headers
  } */

/* Style for the active tab */
/* .nav-tabs .nav-item.active .nav-link {
    // Your custom styles for the active tab
  } */

/* Style for the tab content */
/* .tab-pane {
    // Your custom styles for the tab content
  } */