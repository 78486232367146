/* Add styles for active link */
.scrollspy .active {
    font-weight: bold;
  }
  .scrollcontainer{
    position: fixed;
  
  }
  
  /* Style your scrollspy container as needed */
  .scrollspy {
    width: 20%;
    list-style: none;
    padding: 0;
    overflow-x: hidden;
    scrollbar-gutter: stable;

    /* top: 20px;  */
    /* background-color: #f0f0f0;  */
  }
  
  /* Style your scrollspy links as needed */
  .scrollspy li {
    margin: 10px 0;
  }
  