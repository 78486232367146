.calculator{
    /* display: flex; */

}
.calculator p{
    display: inline;
}
.calculator p:not(:last-child)::after{
    content: " | ";
    position: absolute;

}