.Homebanner{
    background-image: url("./../LottieJson/testbanner.jpg");
    height: 600px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}
@media screen and (min-width:320px) and (max-width:480px) {
  .Homebanner {
       height: 800px;
  }

}