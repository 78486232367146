
.card{
    position: relative;
    padding: 24px;
    background: #FCFCFC;
    border-radius: 8px ; 
    margin-bottom: 8px;
    overflow-x: auto;

}


.card .head{
    display: flex;
    align-items: center;
    min-height: 40px;
    /* box-shadow: inset 0 0 0 2px #9A9FA5; */
    margin-bottom: 32px;

}
  

.head .title{
    margin-right: auto;
    position: relative;
    display: inline-block;
    padding-left: 32px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.6;
    letter-spacing: -.02em;
}

.card .head .title:before{
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 16px;
    height: 32px;
    transform: translateY(-50%);
    border-radius: 4px;
    background: #B1E5FC;
}

   
 
