.scroll-spy-container {
    display: flex;
    align-items: flex-start;
  }
  
  .scroll-spy-sidebar {
    position: sticky;
    top: 0;
    width: 200px;
    padding: 20px;
    background-color: #f4f4f4;
  }
  
  .scroll-spy-sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .scroll-spy-sidebar li {
    margin-bottom: 10px;
  }
  
  .scroll-spy-sidebar a {
    text-decoration: none;
    color: #333;
  }
  
  .scroll-spy-sidebar a.active {
    font-weight: bold;
  }
  
  .scroll-spy-content {
    width: 100%;
    padding: 20px;
  }
  
  /* Style for the content sections (adjust as needed) */
  .scroll-spy-content > div {
    margin-bottom: 30px;
  }
  
  .contentdiv{
    height: 100vh;
  }

  .nav {
    background-color: #333;
    color: #fff;
    padding: 10px;
    text-align: center;
    width: 100%;
    transition: top 0.3s;
  }
  
  .sticky {
    position: fixed;
    top: 0;
  }
  