
.list{
    display: flex;
    justify-content: space-between;
}

.item{
    display: block;
    padding: 0 32px 0 64px;
    color: #1A1D1F;
}

.icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin-bottom: 24px;
    border-radius: 50%;
}

.label{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    color: #6F767E;
}

.counter{
    min-width: 100px;
    font-size: 48px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -.03em;
}
   
@media screen and (min-width:320px) and (max-width:768px){
    .list{
        width: 100%;
        flex-direction: column;
    }
    .item{
        display: flex;
        align-items: center;
        padding: 4px 0;
       
    }
    
    .counter{   
        font-size: 24px;
    }
}
