.container1{
    display: flex;
    justify-content: space-around;
    padding: 0 80px;
}
.content{
   
}

.sections{
    height: auto;
    width: 100%;
    padding: 20px 0;
}
.scrolldiv{
    /* flex: 0 0 30%; */
    /* word-wrap: break-word;  */
}
.contentdiv{
    flex: 0 0 70%;
}