/* .title{
    width: 100%;
    flex-grow: 1;
    margin: 0 0 16px;
    margin-right: auto;
} */
/* .title::before{
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 16px;
    height: 32px;
    transform: translateY(-50%);
    border-radius: 4px;
    background: #B1E5FC;
} */


.button {
    margin-left: 16px;
}

.button-small {
    height: 40px;
    padding: 0 16px;
    border-radius: 8px;
    font-size: 13px;
    background: #2A85FF;
    color: #FCFCFC;
}

.wrapper {
    margin: 0 -24px;
    background: #FCFCFC;
}


.table {
    display: table;
    width: 100%;
}


.wrapper .table .row {
    display: table-row;
}

/* .col{
    background: rgba(#EFEFEF, .35);
} */


.col {
    display: table-cell;
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71429;
    width: auto;
}

.status {
    flex-shrink: 0;
    white-space: nowrap;
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    color: #1A1D1F;
    border: none;
}


/* .details{
    display: none;
} */


.col .product {
    color: #33383F;
    border: none;
    padding: 0;
}


.col .invoice {
    margin-top: 4px;
    font-weight: 500;
    color: #6F767E;
    padding: 0;
    border: none;
}


.negative {
    color: #FF6A55;
    border: none;
}


.foot {
    padding: 12px 0;
    text-align: center;
}


.label {
    margin-right: auto;
}

.searchbox {
    background-color: #F4F4F4;
    padding: 8px 10px;
    border-radius: 10px;
    outline: none;
}

.paginationBttns li {
    margin: 0 4px;
    background-color: #F4F4F4;
    padding: 3px 5px;
    border-radius: 3px;
}

.tabletscreen {
    display: none !important;
}

@media screen and (min-width:320px) and (max-width:768px) {

    .tabletscreen {
        display: none;
    }

    .card {}

    .card .head {
        display: block;
    }

    .head .title {
        display: block;
        width: 100%;
    }

}