.herodiv {
  /* border: 1px solid; */
  background-color: #2960de;
  border-radius: 30px;
  height: auto;
}

.phrasetext {
  font-weight: bold;
}

.box {
  height: auto;
  width: 100%;
  max-width: 16.5rem;
  border: 1px solid;
  border-radius: 0.8rem;
  display: flex;
  padding: 0.5rem;
  background: #FFF;

}

.qrcode {
  height: 15vh;
}

.downloadbtn {
  padding: 15px 30px;
}

.animation-container {
  position: relative;
  height: 1.2em;
  /* Set to appropriate height */
  overflow: hidden;
  display: block;
}

.animation {
  position: absolute;
  opacity: 0;
  transform: translateY(-6%);
  animation: fadeOut 2s ease-out;
  transition: opacity 1s, transform 1s;
}

/* .heading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  } */

.sabke-liye {
  display: block;
  font-family: "Nayuki";
  font-weight: 300;
}

.active {
  opacity: 1;
  transform: translateY(0);
}

.inactive {
  opacity: 0;
}

.lottiefile {
  height: max-content;
  width: 27rem;
  display: flex;
  justify-content: end;
  align-items: center;

}

.earzphone {
  width: auto;

}

.getinstantwrapper {
  padding: 80px 0 10px 0;
  /* padding-top:; */
}

.healthcontent {
  margin-top: 4rem;
}

.healthcontent1 {
  margin-top: 4rem;
}


.healthsub {
  height: 14rem;
}

.healthnowmarquee {
  font-size: 42px;
  font-family: Nayuki;
  display: flex;
  align-items: center;
}

.marquee-container {
  display: flex;
  overflow: hidden;
}

.healthnowmarquee {
  width: 100%;
  height: 75px;
  font-size: 42px;
  font-family: 'Nayuki';
  white-space: nowrap;
  animation: marqueeAnimation2 50s linear infinite;

}

.marquee-container1 {
  display: flex;
  overflow: hidden;
  background-color: #40f29a;

}

.marquee-container2 {
  display: flex;
  overflow: hidden;
  background-color: #C9EBFF;

}

.marquee-container3 {
  display: flex;
  overflow: hidden;
}

.downloadmarquee {
  width: 100%;
  height: 78px;
  font-size: 42px;
  font-family: 'Nayuki';
  white-space: nowrap;
  animation: marqueeAnimation 50s linear infinite;
}

.paylatermarquee {
  width: 100%;
  height: 75px;
  font-size: 40px;
  font-family: 'Nayuki';
  white-space: nowrap;
  animation: marqueeAnimation 50s linear infinite;
}

.paylatermarquee {
  background-color: #C9EBFF;
}

@keyframes marqueeAnimation2 {
  from {
    transform: translateX(-400%);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes marqueeAnimation {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-400%);
  }
}


.paylatermarquee {
  font-size: 42px;
  font-family: Nayuki;
  display: flex;
  align-items: center;
}

.arthshalawrapper {
  margin-top: 100px;
}

.arthshaala {
  display: flex;
  justify-content: space-between;
}

.artshalaimage {
  height: 60px;
  width: 200px;
}


/* ----------------------------------------------
 * Generated by Animista on 2023-10-17 14:26:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.slide-top {
  -webkit-animation: slide-top .5s cubic-bezier(.25, .46, .45, .94) both;
  animation: slide-top .5s cubic-bezier(.25, .46, .45, .94) both
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.logo-animation {
  animation-name: moveLogo;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}



.featured {
  display: flex;
  flex-wrap: wrap;
  height: 20vh;

}

.brandlogo {
  height: auto;
  width: 8rem;
}


.instantcredit {
  background-image: url("/public/images/NewRib.svg");
  height: 30vh;
  width: 100%;
  background-position: center center;
  background-size: cover;

}

.scrollblog::-webkit-scrollbar {
  display: none;
}

.rewardimage {
  height: 25.6667rem;
  width: auto;
}

.columndiv {
  position: relative;
  height: 200px;
  width: 100%;
  z-index: 4;
}

.columndiv1 {

  position: relative;
  height: 200px;
  width: 100%;
  z-index: 4;
}

/* .ribbondiv{
  position: absolute;
  bottom: 10px;
  background-image: url("/public/images/NewRib.svg");
  height: 350px;
  width: 120%;
  background-position:center center ;
  background-size:contain;
  background-repeat: no-repeat;  
  z-index: -1;
  margin: 0px -120px;

} */

.ribbondiv {
  position: absolute;
  bottom: -20px;
  /* background-image: url("/public/images/NewRib.svg"); */
  height: 350px;
  width: 120%;
  /* background-position:center center ;
  background-size:contain;
  background-repeat: no-repeat;   */
  z-index: -1;
  margin: 0px -120px;

}

.confitee {
  position: absolute;
  bottom: 00px;
  left: 0px;
  /* background-image: url("/public/images/NewRib.svg"); */
  height: 1000px;
  width: 100%;

  /* background-position:center center ;
  background-size:contain;
  background-repeat: no-repeat;   */
  z-index: 99;
  margin: 0px;

}


.somedoubt {
  padding: 0;
}

.downloadmarqueewrapper {
  padding-top: 100px;
}

.downloadmarquee {
  font-size: 100px;
  font-family: Nayuki;
  display: flex;
  align-items: center;
  color: #D0E6C2;
}

.ribbondiv1 {
  position: absolute;
  bottom: 50px;
  /* background-image: url("/public/images/NewRib.svg"); */
  height: 350px;
  width: 120%;
  /* background-position:center center ;
  background-size:contain;
  background-repeat: no-repeat;   */
  z-index: -1;
  margin: 0px -120px;

}

@keyframes moveLogo {
  from {
    transform: translateX(-50px);
    /* Initial position */
  }

  to {
    transform: translateX(0px);
    /* Final position (adjust the value) */
  }
}

.scanqr {
  font-size: 19px;
}


.mobilescreen {
  padding: 0;
  height: auto;
  width: auto;
}

.spotlight {
  height: 160px;
  width: 155px;
}

.ribbonrelative {
  position: relative;
  padding: 0 10px 50px 10px;
  /* margin-bottom: 4rem; */
}

.line {
  position: absolute;
  top: -10px;
  right: 0px;
  height: 100px;
  width: fit-content;

}

.lottieribbontop {
  height: 400px;
  width: 600px;
}

.herocontent1 {
  z-index: 5;
  padding-left: 3rem;
  margin-top: 4.2rem;
}

.innereazrFeature {
  flex: 0 0 48%;
  margin: auto;
}



/* @media screen and (min-width:768px) and (max-width:1240px) {
  .logo-animation {
    animation-name: moveLogo;
    animation-duration: 1s;
    
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
   
  }

  @keyframes moveLogo {
    from {
      transform: translateX(-10);
      
    }

    to {
      transform: translateX(10px);
    
    }
  }
} */



@media screen and (min-width:320px) and (max-width:768px) {
  .box {
    margin: auto;
    flex-direction: column;
    width: 10rem;
    height: auto;

  }

  .earzphone {
    height: 50vh;
  }

  .qrcode {
    width: 12vh;
    height: 12vh;
    margin: auto;
    margin-bottom: 0.5rem;
  }

  .lottiefile {
    height: 100%;
    width: 100%;
    justify-content: center;
  }

  .healthcontent {
    margin-top: 2rem;
  }

  .healthcontent1 {
    margin-top: 0rem;
  }

  .featured {
    height: max-content;
  }

  .rewardimage {
    height: 22.6667rem;
    width: auto;
  }

  .getinstantwrapper {
    padding: 40px 0;
  }

  .healthsub {
    height: 14rem;
  }

  /* .ribbondiv{
    position: absolute;
    bottom: 75px;
    background-image: url("/public/images/NewRib.svg");
    height: 350px;
    width: 150%;
    background-position:center center ;
    background-size:contain;
    background-repeat: no-repeat;  
    z-index: -1;
    margin: 0px -83px;
  
  } */

  .columndiv {
    height: 80px;

  }

  .ribbondiv {
    position: absolute;
    bottom: -10px;
    /* background-image: url("/public/images/NewRib.svg"); */
    /* height: 350px; */
    /* width: 150%; */
    /* background-position:center center ;
    background-size:contain;
    background-repeat: no-repeat;   */
    z-index: -1;
    margin: 0px -83px;

  }

  .confitee {
    position: absolute;
    bottom: 200px;
    left: 0px;
    /* background-image: url("/public/images/NewRib.svg"); */
    height: 500px;
    width: 100%;
    /* background-position:center center ;
    background-size:contain;
    background-repeat: no-repeat;   */
    z-index: 99;
    margin: 0px;

  }

  .downloadbtn {
    padding: 10px 20px;
  }

  .artshalaimage {
    height: 40px;
    width: 100px;
  }



  .logo-animation {
    animation-name: moveLogo;
    animation-duration: 1s;
    /* Adjust the duration as needed */
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    /* Keeps the final state after animation */
  }

  @keyframes moveLogo {
    from {
      transform: translateX(-10);
      /* Initial position */
    }

    to {
      transform: translateX(15px);
      /* Final position (adjust the value) */
    }
  }

  /* .dogwalkwrapper{
    padding: 1.5rem;
  } */

  .herocontent1 {
    padding-left: 0px;
    margin-top: 3rem;
  }

  .spotlight {
    height: 140px;
    width: 100%;
    max-width: 70%;
  }

  .innereazrFeature {
    flex: 0 0 100%;
  }

  .line {
    top: -5px;
    /* Adjust top position for smaller screens */
    height: 92px;
  }

}