@tailwind base;
@tailwind components;
@tailwind utilities;

.text__12 {
    @apply text-[12px]
}

.text__14 {
    @apply text-[12px] lg:text-[14px]
}

.text__15 {
    @apply text-[14px] lg:text-[15px]
}

.text__16 {
    @apply text-[14px] lg:text-[16px]
}

.text__18 {
    @apply text-[16px] lg:text-[18px]
}

.text__20 {
    @apply text-[18px] lg:text-[20px]
}

.text__24 {
    @apply text-[18px] md:text-[20px] lg:text-[24px]
}

.text__32 {
    @apply text-[20px] sm:text-[24px] md:text-[28px] lg:text-[32px]
}

.text__48 {
    @apply text-[24px] md:text-[28px] lg:text-[32px] xl:text-[48px]
}

.text__56 {
    @apply text-[28px] md:text-[32px] lg:text-[48px] xl:text-[56px]
}

.text__64 {
    @apply text-[32px] md:text-[48px] lg:text-[56px] xl:text-[64px]
}

.text__72 {
    @apply text-[36px] md:text-[56px] lg:text-[64px] xl:text-[72px]
}

.text__60 {
    @apply text-[38px] md:text-[44px] lg:text-[52px] xl:text-[60px]
}

.text__78 {
    @apply text-[44px] md:text-[48px] lg:text-[56px] xl:text-[64px]
}

.text__feature {
    @apply text-[16px] lg:text-[14px]
}