
.label{
    margin-bottom: 14px;

}
  

.tooltip{
    position: relative;
    top: -1px;
}
  
   

.dropdown{
    position: relative;
    z-index: 3;
}

.dropdown .head{
    /* box-shadow: inset 0 0 0 2px #9A9FA5; */
    background: #FFFFFF;
}

.body{
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}
          
.dropdown .body .head{
    height: 40px;
    padding: 0 40px 0 16px;
    line-height: 40px;
   box-shadow: inset 0 0 0 2px #9A9FA5;
}
 
/* .head:before{
    width: 40px;
    background-size: 16px auto;
} */
          
.option{
    padding: 4px 16px;

}

/* .head{
    position: relative;
    height: 48px;
    padding: 0 48px 0 12px;
    border-radius: 12px;
    box-shadow: inset 0 0 0 2px #EFEFEF;
    font-size: 14px;
    font-weight: 600;
    line-height: 48px;
    color: #1A1D1F;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    user-select: none;
    transition: all .2s;
} */

/* .head{
    display: flex;
    align-items: center;
    min-height: 40px;
   
} */
.head::before{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 48px;
    /* background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12.707 15.707l-.094.083a1 1 0 0 1-1.32-.083h0l-6-6-.083-.094a1 1 0 0 1 1.497-1.32h0L12 13.585l5.293-5.292.094-.083a1 1 0 0 1 1.32 1.497h0l-6 6z' fill='%236f767e'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto; */
    transition: transform .2s;
    /* background-color: #B1E5FC; */

    /* box-shadow: inset 0 0 0 2px #9A9FA5; */
}
    

.selection{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.body{
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    right: 0;
    padding: 8px 0;
    border-radius: 12px;
    background: #FFFFFF;
    box-shadow: inset 0 0 0 2px #EFEFEF, 0 4px 12px rgba(#F4F4F4, .1);
    visibility: hidden;
    opacity: 0;
    transform: translateY(3px);
    transition: all .2s;
}

.body .bodyUp{
    top: auto;
    bottom: calc(100% + 2px);
}
   

.option{
    padding: 8px 12px;
    color: #6F767E;
    transition: color .2s;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
 
.option:hover{
    color: #1A1D1F;

}
       
.option .selectioned{
    color: #2A85FF;
}
        

@media screen and (min-width:320px) and (max-width:768px){
    .dropdown{
    float: none;
    width: auto;
    height: 48px;
    padding: 0 48px 0 16px;
    background: none;
    box-shadow: inset 0 0 0 2px #EFEFEF;
    border-radius: 12px;
    border: none;
    opacity: 1;
    font-size: 14px;
    font-weight: 600;
    line-height: 48px;
    color: #1A1D1F;
    }
}