  .line {
    z-index: 99 !important;
    position: absolute;
    top: -10px;
    right: 0px;
    height: 100px;
    width: fit-content;

  }

  .lottieribbontop {
    height: 400px;
    width: 600px;
  }

  .eazrlogo {
    height: 100%;
    width: 100%;

  }

  .eazraboutlogo {
    height: 339px !important;
    width: 200px !important;
  }


  /* @media screen and (min-width:768px) and (max-width:1240px) {
    .logo-animation {
      animation-name: moveLogo;
      animation-duration: 1s;
      
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
     
    }
  
    @keyframes moveLogo {
      from {
        transform: translateX(-10);
        
      }
  
      to {
        transform: translateX(10px);
      
      }
    }
  } */







  @media screen and (min-width:320px) and (max-width:768px) {

    .line {
      top: -5px;
      /* Adjust top position for smaller screens */
      height: 95px;
    }

    .eazrlogo {
      height: 100%;
      width: 400px;
    }

    .eazraboutlogo {
      width: 230px !important;
      height: 200px !important;
    }

  }